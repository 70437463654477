<template>
  <div :style="$vuetify.breakpoint.smAndDown ? { padding: '6% 0%' } : { padding: '2% 5%' }">
    <div v-if="currentProduct">
      <vs-row :style="{ padding: '5% 0' }">
        <vs-col :w="$vuetify.breakpoint.smAndDown ? 12 : 7">
          <v-carousel :height="$vuetify.breakpoint.smAndDown ? '550px' : '750px'" hide-delimiter-background
            hide-delimiters>
            <v-carousel-item v-for="(image, index) in currentProduct.productImages" :key="index" :src="image" cover>
              <template v-slot:placeholder>
                <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
              </template>
            </v-carousel-item>
          </v-carousel>
        </vs-col>
        <vs-col :w="$vuetify.breakpoint.smAndDown ? 12 : 5">
          <div :style="$vuetify.breakpoint.smAndDown ? { padding: '2% 5%' } : { padding: '0 10%' }">
            <div v-if="currentProduct.productTags" :style="{ fontSize: '15px', paddingBottom: '5px' }">
              <strong>{{ currentProduct.productTags.join(', ') }}</strong>
            </div>
            <div :style="{ fontSize: '30px', paddingBottom: '5px' }">
              {{ currentProduct.productTitle }}
            </div>
            <div :style="{ paddingBottom: '20px', fontWeight: 'bold' }">
              <i class="fa-solid fa-bangladeshi-taka-sign"></i> {{ currentProduct.productPrice }}
            </div>
            <div v-if="currentProduct.productQuantity < 10 && currentProduct.productQuantity != 0"
              :style="{ paddingBottom: '20px', color: COLORS.RED, fontWeight: 'bold' }">
              Only {{ currentProduct.productQuantity }} left in stock.
            </div>
            <div v-if="currentProduct.productQuantity == 0"
              :style="{ paddingBottom: '20px', fontWeight: 'bold', color: COLORS.RED }">
              Out of Stock
            </div>
            <div v-if="totalQuantity.length">
              <div v-if="currentProduct.productColors.length" :style="{ fontSize: '14px', color: COLORS.DARK_GRAY }">
                Color: {{ selectedColor }}
              </div>
              <v-radio-group v-if="currentProduct.productColors.length" dense v-model="selectedColor" row>
                <v-radio v-for="item in currentProduct.productColors" :key="item" :color="COLOR_MAP[selectedColor]"
                  :label="item" :value="item"></v-radio>
              </v-radio-group>
              <div :style="{ color: COLORS.DARK_GRAY, fontSize: '14px' }"> Selected Size: {{ (this.isDressSet ? `Top: ${this.size}` : this.size) + (this.isDressSet ? ` | Pant: ${this.pant}` : '') }} </div>
              <v-row justify="left" align="center">
                <v-col cols="3">
                  <input v-model="size" @click="resetSizeIfCustom" :placeholder="isDressSet? 'TOP' : '40 / M'"
                    style="text-align: center; width: 72px; border-radius: 8px; border: 1px solid #ccc; padding: 4px 0;" />
                </v-col>
                <v-col v-if="isDressSet && size != 'Custom'" cols="3">
                  <input v-model="pant" @click="resetSizeIfCustom" placeholder="PANT"
                    style="text-align: center; width: 72px; border-radius: 8px; border: 1px solid #ccc; padding: 4px 0;" />
                </v-col>
                <v-col cols="6">
                  <v-radio-group row v-model="size">
                    <v-radio label="Custom" value="Custom"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <div v-if="size === 'Custom'"> We make all our clothing catered to your specific body. Please provide the
                following information:
                <v-textarea v-if="currentProduct.productQuantity > 0" outlined :style="{ paddingTop: '20px' }"
                  label="Measurements" v-model="measurements"></v-textarea>
              </div>
              <!-- <div>
                <div v-if="currentProduct.productQuantity">
                  <v-btn small elevation="2" icon><span style="font-size: 18px;" @click="decreaseQuantity()"
                      class="material-symbols-outlined">remove</span></v-btn>
                  <input type="number" v-model="quantity" min="1" :max="currentProduct.productQuantity"
                    @input="validateQuantity"
                    style="text-align: center; margin: 0 8px; border-radius: 8px; border: 1px solid #ccc; padding: 4px;" />
                  <v-btn small elevation="2" icon><span style="font-size: 18px" @click="increaseQuantity()"
                      class="material-symbols-outlined">add</span></v-btn>
                  <v-btn style="margin-left: 18px;" dark rounded @click.prevent="addToBag()">Add to Bag (<i
                      class="fa-solid fa-bangladeshi-taka-sign"></i> {{ currentProduct.productPrice * quantity
                    }})</v-btn>
                </div>
              </div> -->
              <div v-if="totalQuantity.length">
                <v-select label="Quantity" filled v-model="quantity" type="number" :items="totalQuantity"
                  required></v-select>
                <v-btn dark rounded @click.prevent="addToBag()"> Add to Bag </v-btn>
              </div>
              <div v-if="currentProduct.productSizeChart" style="margin-top: 18px;">
                <v-btn dark rounded @click="sizeDialog = !sizeDialog">Size Chart</v-btn>
              </div>
              <v-dialog v-if="currentProduct.productSizeChart" v-model="sizeDialog" max-width="600px"
                max-height="600px">
                <v-card class="d-inline-flex flex-column align-center">
                  <v-card-title style="font-size: 24px; font-weight: bold;">Size Chart</v-card-title>
                  <v-card-text class="text-center">
                    <pre :style="{ fontFamily: 'Cormorant Infant, serif', fontSize: '18px', fontWeight: '600' }">{{
                      currentProduct.productSizeChart }}</pre>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <v-list :style="{ paddingBottom: '5%' }">
        <v-list-group v-if="currentProduct.productDescription && currentProduct.productDescription.length > 0">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :style="{ fontSize: '28px' }">Product Description</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item>
            {{ currentProduct.productDescription }}
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :style="{ fontSize: '28px' }">Shipping and Return</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item>
            It takes around 6-7 business days to deliver the product.
          </v-list-item>
          <v-list-item>
            Shipping Fee inside Dhaka: <i class="fa-solid fa-bangladeshi-taka-sign" style="margin-left: 4px;"></i> {{
              DHAKA_DELIVERY_FEE }}
          </v-list-item>
          <v-list-item>
            Shipping Fee outside Dhaka: <i class="fa-solid fa-bangladeshi-taka-sign" style="margin-left: 4px;"></i> {{
              OUTSIDE_DHAKA_DELIVERY_FEE }}
          </v-list-item>
          <v-list-item>
            You can return the product if you're not happy with it.
          </v-list-item>
        </v-list-group>
      </v-list>
      <div v-if="bestSellers.length" :style="{ fontSize: '28px', paddingBottom: '5px', textAlign: 'center' }">
        You May Also Like
        <YouMayLike :products="bestSellers" />
      </div>
    </div>
    <v-alert v-if="prodQuantityInBag && currentProduct" class="floating-alert" dense rounded :color="COLORS.SECONDARY"
      colored-border elevation="4" icon="mdi-sale" border="left">
      {{ prodQuantityInBag }} {{ currentProduct.productTitle }} currently in bag.
    </v-alert>
    <Alert :snackbar="alert" :text="alertMessage" :color="alertColor" @updateSnackbar="closeSnackbar" />
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
import { DHAKA_DELIVERY_FEE, OUTSIDE_DHAKA_DELIVERY_FEE, COLOR_MAP } from '@/plugins/utils.js';

export default {
  name: 'ViewProduct',
  components: {
    YouMayLike: () => import('@/components/YouMayLike.vue'),
    Alert: () => import('@/components/Alert.vue'),
    ImageGallery: () => import('@/components/ImageGallery.vue'),
  },
  data() {
    return {
      currentProduct: null,
      selectedColor: null,
      size: 'M',
      measurements: null,
      quantity: 1,
      totalQuantity: [],
      alert: false,
      alertColor: null,
      alertMessage: null,
      sizeDialog: false,
      COLORS,
      DHAKA_DELIVERY_FEE,
      OUTSIDE_DHAKA_DELIVERY_FEE,
      COLOR_MAP,
      pant: "M"
    };
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    bestSellers() {
      return this.$store.state.bestSellers.filter(
        (post) => post.productId !== this.productId && post.productQuantity > 0
      );
    },
    prodQuantityInBag() {
      return this.$store.state.bag
        .filter((post) => post.productId === this.productId)
        .reduce((total, post) => total + post.quantity, 0);
    },
    isDressSet() {
      return this.currentProduct && this.currentProduct.productTags.includes('Dress Sets');
    },
  },
  mounted() {
    this.loadProduct();
  },
  watch: {
    productId() {
      if (this.productId) {
        this.loadProduct();
      }
    },
    prodQuantityInBag() {
      this.loadQuantity();
    },
  },
  methods: {
    // validateQuantity() {
    //   if (this.quantity >= this.currentProduct.productQuantity - this.prodQuantityInBag) {
    //     this.quantity = this.currentProduct.productQuantity - this.prodQuantityInBag;
    //   } else if (this.quantity < 1) {
    //     this.quantity = 1;
    //   }
    // },
    // increaseQuantity() {
    //   if (this.quantity < this.currentProduct.productQuantity - this.prodQuantityInBag) {
    //     this.quantity++;
    //   }
    // },
    // decreaseQuantity() {
    //   if (this.quantity > 1) {
    //     this.quantity--;
    //   }
    // },
    resetSizeIfCustom() {
      if (this.size === 'Custom') {
        this.size = 'M';
        this.pant = 'M';
      }
    },
    closeSnackbar(bool) {
      this.alert = bool;
    },
    loadQuantity() {
      if (this.currentProduct && this.size) {
        this.totalQuantity = Array.from(
          { length: this.currentProduct.productQuantity - this.prodQuantityInBag },
          (_, i) => i + 1
        );
      }
    },
    async loadProduct() {
      this.measurements = null;
      if (this.$store.state.productLoaded) {
        this.currentProduct = await this.$store.state.products.filter((post) => post.productId === this.productId)[0];
      }
      this.$store.commit('setProductState', this.currentProduct);
      this.selectedColor = this.currentProduct.productColors[0];

      this.loadQuantity();

      if (this.currentProduct.productTags.includes('Tops')) {
        this.measurements = 'Bust: ';
      }
      if (this.currentProduct.productTags.includes('Bottoms')) {
        this.measurements = 'High-Waist: \nLow-Waist: ';
      }
      if (this.currentProduct.productTags.includes('Dresses')) {
        this.measurements = 'Bust: \nWaist: \nLength: ';
      }
      if (this.currentProduct.productTags.includes('Dress Sets')) {
        this.measurements = 'Bust (Top): \nWaist (Bottom): ';
      }
    },
    addToBag() {
      if (this.currentProduct.productColors.length > 0 && this.selectedColor === null && this.quantity !== null && this.measurements !== null && this.size !== "") {
        this.alertColor = COLORS.RED;
        this.alertMessage = 'All fields are required!';
        this.alert = true;
        return;
      }
      const data = {
        productId: this.currentProduct.productId,
        selectedColor: this.selectedColor,
        productTitle: this.currentProduct.productTitle,
        productPrice: this.currentProduct.productPrice,
        measurements: this.size === 'Custom' ? this.measurements : (this.isDressSet ? `Top: ${this.size}` : this.size) + (this.isDressSet ? ` | Pant: ${this.pant}` : ''),
        quantity: this.quantity,
        maxQuantity: this.currentProduct.productQuantity,
        productImage: this.currentProduct.productImages[0],
      };
      this.$store.commit('addToBag', data);
      this.quantity = 1;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'Discover the latest trends at Bengalina, Dhaka’s leading clothing brand. We offer exclusive Western and traditional wear, including tops, dresses, bottoms, and co-ords designed for every style story.',
        },
        {
          property: 'og:title',
          content: `${this.currentProduct.productTitle} | Bengalina - Dhaka, Bangladesh`,
        },
        {
          property: 'og:description',
          content: 'Explore Bengalina’s latest collection featuring Western and traditional styles. From chic tops and elegant dresses to versatile bottoms and coordinated sets, Bengalina has your style covered.',
        },
        {
          name: 'keywords',
          content: 'Bengalina, clothing brand Dhaka, Bangladesh fashion, Western wear Bangladesh, traditional wear Dhaka, tops, dresses, bottoms, co-ords, dress sets, new collection, kameez',
        },
        {
          property: 'og:image',
          content: this.currentProduct.productImages[0],
        },
        {
          property: 'og:image:alt',
          content: `${this.currentProduct.productTitle} image`,
        },
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": this.currentProduct.productTitle, 
            "image": this.currentProduct.productImages[0], 
            "description": this.currentProduct.productDescription, 
            "brand": {
              "@type": "Brand",
              "name": "Bengalina"
            },
            "offers": {
              "@type": "Offer",
              "url": this.currentProduct.productImages[0], 
              "priceCurrency": "BDT", 
              "price": this.currentProduct.productPrice, 
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock"
            }
          }
        }
      ]
    }
  },
};
</script>

<style>
.floating-alert {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1000;
  font-weight: bold;
}
</style>